import React, { useState, useEffect } from "react";
import Promo from '../assets/promo.svg'
import Coin from '../assets/coin.svg'
import Button from '../components/Button'
import Footer from '../components/Footer/Footer'
import { RelayProvider, wrapContract } from "@opengsn/provider";
import promoRaffleAbi from "../utils/promoRaffle.json";
import { ethers } from "ethers";
const { Paymaster, PromoRaffle, PromoRaffleNFT } = require('../utils/addresses');


const PromoRafflePage = (props) => {
  const [but, setBut] = useState(false)
  const getPromoRaffleTicket = async() => {
      const from = props.account;
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      let signer = provider.getSigner();
      //let provider = new Web3(window.ethereum);    
      const config = {
        paymasterAddress: Paymaster,
        useClientDefaultConfigUrl: false,
        performDryRunViewRelayCall: false,
        preferredRelays: [`https://relay.starfish.technology/`],
        loggerConfiguration: {
          logLevel: 'debug'
        }
      }
   try {
      const relayProvider = await RelayProvider.newProvider({ provider: provider, config }).init();    
      const web3 = new ethers.providers.Web3Provider(relayProvider);    
      const myRecipient = new ethers.Contract(PromoRaffle, promoRaffleAbi, signer);    
      const gsnContract = await wrapContract(myRecipient, config); 
      const tx = await gsnContract.enterRaffle();
      console.log("TX:  ", tx);
      if(tx) {
        setBut(true)
      }

    } catch (error) {
      console.log("Connect Error: ", error);
    }

  }

  const addPromoRaffleTicket = async() => {
    try {
      // wasAdded is a boolean. Like any RPC method, an error can be thrown.
      let provider = new ethers.providers.Web3Provider(window.ethereum);
      let signer = provider.getSigner();
      const wasAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC721', // Initially only supports ERC-20 tokens, but eventually more!
          options: {
            address: PromoRaffleNFT, // The address of the token.
            symbol: "PromoRaffle", // A ticker symbol or shorthand, up to 5 characters.
            decimals: 0, // The number of decimals in the token.
            //image: tokenImage, // A string URL of the token logo.
          },
        },
      });
    
      if (wasAdded) {
        console.log('Thanks for your interest!');
      } else {
        console.log('Your loss!');
      }
    } catch (error) {
      console.log(error);
    }

}
  return (
    <>
      <div className='w-full h-full py-10 mx-auto md:w-10/12 max-w-7xl'>
        <div className='flex flex-col items-center justify-between w-full gap-5 md:gap-10 md:flex-row'>
          <div className='w-10/12 md:w-1/2'>
            <img src={Promo} alt='Promo' />
          </div>
          <div className='flex flex-col justify-between w-10/12 h-auto gap-5 md:w-1/2'>
            <h3 className='text-4xl font-medium'>
              Try Raffle Market with a free promo raffle campaign
            </h3>
            <p className='text-[#9C9C9C] text-left'>
            Are you curious to see how our raffle platform works? Look no further! We invite you to try our platform in action and experience firsthand the power of our tools and resources.
            </p>            
            <div className='flex items-center gap-5'>
              <span className='text-[#E1E1E1] text-4xl'>$0</span>
              <img src={Coin} alt='Coin' />
            </div>
            <div className='flex items-center w-full gap-3 mb-10'>
            { <Button onClickHandler={getPromoRaffleTicket} text='Join' accent additionalClass='w-full md:w-auto' />
              //: <Button onClickHandler={addPromoRaffleTicket} text='Add NFT to Metamask' accent additionalClass='w-full md:w-auto' />
            }
            </div>
          </div>
        </div>

        <div className='flex flex-col items-start justify-start w-full gap-5 mt-20 mb-10 md:mb-40'>
          <h3 className='text-3xl ml-5 md:ml-0'>How It’s Work ?</h3>

          <div className='bg-[#14161B] flex flex-col md:flex-row items-center justify-start w-full rounded-md p-5 h-auto md:h-[100px]'>
            <h4 className='flex items-start justify-center w-2/12 text-4xl'>01</h4>
            <p className='w-full md:w-1/2'>
              All you need to do is sign the transaction to receive the NFT in your wallet. We cover the gas fee for transactions.
            </p>
          </div>
          <div className='bg-[#14161B] flex flex-col md:flex-row items-center justify-start w-full rounded-md p-5 h-auto md:h-[100px]'>
            <h4 className='flex items-start justify-center w-2/12 text-4xl'>02</h4>
            <p className='w-full md:w-1/2'>
            For every N new users, we offer a chance to win X amount of free MATIC tokens!
            </p>
          </div>
          <div className='bg-[#14161B] flex flex-col md:flex-row items-center justify-start w-full rounded-md p-5 h-auto md:h-[100px]'>
            <h4 className='flex items-start justify-center w-2/12 text-4xl'>03</h4>
            <p className='w-full md:w-1/2'>
            So why wait? Sign up today and see how our Raffle Market platform can transform your efforts into a powerful force for change.
            </p>
          </div>
        </div>
      </div>
      <div className='w-full'>
        <Footer />
      </div>
    </>
  )
}

export default PromoRafflePage
