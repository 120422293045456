import React from 'react'
import Hero from '../components/Hero/Hero'
import FreeTicket from '../components/FreeTicket/FreeTicket'
import Reviews from '../components/RaffleCard/Reviews'
import TextCarousel from '../components/TextCarousel/TextCarousel'
import { ReactComponent as Background } from '../assets/bg-1.svg'
import { ReactComponent as BackgroundMobile } from '../assets/bg-1-mobile.svg'

import CarouselTextItem from '../components/CarouselTextItem'
import ProductCarousel from '../components/ProductCarousel/ProductCarousel'
import CTA from '../components/CTA/CTA'
import Footer from '../components/Footer/Footer'

const LandingPage = ({ address, connectWalletHandler, raffles }) => {
  return (
    <div className='relative z-10 overflow-hidden'>
      <Hero address={address} connectWalletHandler={connectWalletHandler} raffle={raffles[raffles.length - 1]} />
      <div className='relative mobile-bg-container '>
        <BackgroundMobile className='absolute right-0 z-10 md:hidden bg top-120 ' />
      </div>
      <Reviews />
      <div className='relative w-full '>
        <Background className='absolute hidden md:block -left-20 -top-48 bg ' />
      </div>
      <FreeTicket />
      <ProductCarousel title='Featured raffle' raffles={raffles} />      
      <CTA />
      <Footer />
    </div>
  )
}

export default LandingPage
