import React from "react";
import Button from "../Button";
import { ReactComponent as Background } from "../../assets/bg-2.svg";
import { Link, useNavigate } from "react-router-dom";
import "./Hero.css";
import RaffleCardLink from "../RaffleCardLink/RaffleCardLink";
import { Navigate } from "react-router-dom";

const Stat = ({ num, text }) => (
  <div className="text-center">
    <h3 className="font-clashDisplay font-bold text-2xl">{num}</h3>
    <p className="text-xs">{text}</p>
  </div>
);

const Hero = ({raffle}) => {
  const navigate = useNavigate();

  return (
    <div className="w-full text-white py-12 md:px-12 px-5 ">
      <h1 className=" hero-title  font-clashDisplay text-black font-bold md:text-8xl text-4xl text-center  tracking-wide">
        SMART CONTRACT
      </h1>
      <div className="subtitle font-clashDisplay font-bold md:text-3xl text-2xl text-center w-full sm:w-4/5 mx-auto ">
        <h3 className="mt-6 tracking-wider">
          <span className="bg-gradient-to-l md:px-20 pl-6 py-2 pb-2 rounded-3xl from-[#1d232300] to-[#1D2323]">
            WE MAKE IT EASY TO{" "}
          </span>
          <span className="md:block inline ">
            RUN <span className="text-accent ">RAFFLES ONLINE</span>
          </span>
        </h3>
        <p className="desc  text-text-color font-light w-11/12 md:w-3/6 text-sm md:text-xl  md:mt-4 mt-10 text-center mx-auto">
        We provide you exclusive opportunities to run your own Raffle with a variety of prizes including smartphones, laptops, fine jewelry, cars, and even luxurious apartments or houses.
        </p>
      </div>
      <div className="btn-container  flex xs:flex-row flex-col-reverse gap-6  sm:w-3/5 w-full  items-center justify-center mx-auto my-10">
        <Button
          text="Promo"
          additionalClass={
            "text-accent border border-accent bg-black w-full md:w-auto"
          }
          onClickHandler={()=>{navigate("/promo-raffle")}}
        />
        <Button text={ "View Raffles" } onClickHandler={()=>{navigate("/")}} accent additionalClass="w-full md:w-auto" />
      </div>
      <div className="w-full relative">
        <Background className="absolute  md:-left-12 -left-52 bg -top-60   " />
      </div>
      <div className="stats flex md:w-3/6 w-full mt-24 items-center justify-around  mx-auto">
        <Stat num="2K+" text="Winning amount" />
        <Stat num="200" text="People" />
        <Stat num="10" text="Complete Raffale" />
      </div>

      <div className="content-hero w-full sm:w-5/6  md:w-3/4 md:gap-0  sm:gap-6 sm:items-center md:items-start md:mt-36 mt-24   flex  justify-between flex-wrap mx-auto">
        <RaffleCardLink raffle={raffle} />
        <div className="text-info  sm:w-3/5 md:w-1/2 w-full md:top-11 relative mt-8">
          <h1 className="text-3xl md:text-5xl font-clashDisplay font-bold w-4/5">Raffle Market Online Platform</h1>
          <p className="desc text-sm md:text-xl mt-8 text-text-color font-poppins mb-8 md:mb-12">Organizing a raffle doesn't have to be a complicated process. With Raffle Market powerful platform, we streamline the online raffle fundraising process, running it smoothly from ticket sales to the final draw. </p>
          <Link to="/create" className='text-accent font-clashDisplay md:text-2xl text-3xl font-medium'>Create Your Free Online Raffle > ></Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
