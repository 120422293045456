import React from "react";
import Button from "../Button";
import { ReactComponent as Background } from "../../assets/bg-2.svg";
import { Link, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

const Stat = ({ num, text }) => (
  <div className="text-center">
    <h3 className="font-clashDisplay font-bold text-2xl">{num}</h3>
    <p className="text-xs">{text}</p>
  </div>
);

const FreeTicket = () => {
  const navigate = useNavigate();

  return (
    <>
    <div className="md:w-4/6 w-full text-white mx-auto py-1 md:px-12 px-5 my-10">
      <div className="content-hero w-full md:gap-0  sm:gap-6 sm:items-center md:items-start   flex flex-wrap mx-auto mt-8">        
        <div className="text-info  sm:w-2/5 md:w-1/2 w-full md:top-11 relative md:mt-8">
          <div className="hidden md:block mx-auto md:w-3/5 w-full md:px-2  pt-12 pb-12 rounded-md text-center z-40">            
            <p className='text-2xl font-medium mt-6'>Support for Charitable Causes</p>
            <p className='text-base text-text-color mt-4 w-5/6 mx-auto'>We support charitable causes, empowering individuals and organizations to raise funds for social impact and make a positive difference in the world.</p>
          </div>
          <h1 className="text-3xl md:text-5xl font-clashDisplay font-bold md:w-4/5">Create a Free Raffle</h1>
          <p className="desc text-base md:text-xl mt-8 text-text-color font-poppins mb-8 md:mb-12">Sign up for your free raffle today and start engaging your audience and supporting your cause in a new and exciting way!</p>
          
        </div>
          <img
                src="/images/freeTicket.png"
                className="cursor-pointer md:mt-60 md:ml-20"
                alt=""
                onClick={()=>{navigate("/promo-raffle")}}
              />
      </div>
    </div>
    <div className="w-full    text-white py-12 md:px-12 px-5 ">
        <div className="text-info  md:w-1/2 w-full md:top-11 relative my-8 mx-auto">
          <h1 className="text-3xl md:text-5xl font-clashDisplay w-full md:w-4/5 text-center">Our Raffle platform gives you a numerous opportunities:</h1>
          <div className="flex flex-row w-full md:w-4/5 mt-5 md:ml-10">
            <div className='w-3 h-3 rounded-full bg-accent mt-2 mr-4'></div>
            <div className="w-5/6 text-base md:text-xl text-text-color font-poppins">
              Choose a category and see the community we offer, get free bonus tickets by copying and placing items on your website.
            </div>
          </div>
          <div className="flex flex-row w-full md:w-4/5 mt-5 md:ml-10">
            <div className='w-3 h-3 rounded-full bg-accent mt-2 mr-4'></div>
            <div className="w-5/6 text-base md:text-xl text-text-color font-poppins">
            Propose free Raffle tickets or bonus coupons for items on your website to increase community engagement and promote your selling page
            </div>
          </div>
          <div className="flex flex-row w-full md:w-4/5 mt-5 md:ml-10">
            <div className='w-3 h-3 rounded-full bg-accent mt-2 mr-4'></div>
            <div className="w-5/6 text-base md:text-xl text-text-color font-poppins">
            Choose your raffle ticketing options based on your format and raffle specifics. Social media users may follow, like, reshare, or tag friends, while website users can count tickets toward any prize or require separate tickets for each prize.
            </div>
          </div>
          <div className="flex flex-row w-full md:w-4/5 mt-5 md:ml-10">
            <div className='w-3 h-3 rounded-full bg-accent mt-2 mr-4'></div>
            <div className="w-5/6 text-base md:text-xl text-text-color font-poppins">Choose a prize, add a picture, and a brief description to attract donors, whether you choose one or several depends on your raffle goals.Choose a category and see the community we offer, get free bonus tickets by copying and placing items on your website.
            </div>
          </div>
          <div className="flex flex-row w-full md:w-4/5 mt-5 md:ml-10">
            <div className='w-3 h-3 rounded-full bg-accent mt-2 mr-4'></div>
            <div className="w-5/6 text-base md:text-xl text-text-color font-poppins">
            You can generate a TON multi-check and borrow funds by publishing on your Telegram channel in your private account on our website.
            </div>
          </div>
          <div className="flex flex-row w-full md:w-4/5 mt-5 md:ml-10">
            <div className='w-3 h-3 rounded-full bg-accent mt-2 mr-4'></div>
            <div className="w-5/6 text-base md:text-xl text-text-color font-poppins">Set your own raffle timeframe, stop ticket sales and conduct the drawing at your preferred time. Raffles can last for weeks or months, with longer timeframes leading to more donations and shares.Choose a category and see the community we offer, get free bonus tickets by copying and placing items on your website.
            </div>
          </div>
          
          
        </div>


    </div>
    </>
  );
};

export default FreeTicket;
