import React, { useState, useEffect } from "react";
import Logo from '../Logo'
import { ReactComponent as LinkedIn } from '../../assets/Linked In.svg'
import { ReactComponent as Facebook } from '../../assets/Facebook.svg'
import { ReactComponent as Instagram } from '../../assets/Instagram.svg'
import Button from '../Button'
import { ReactComponent as ArrowIcon } from '../../assets/arrow-icon.svg'
const mailchimp = require('@mailchimp/mailchimp_marketing');

const mailchimpApi = process.env.REACT_APP_API_MAILCHIMP;
mailchimp.setConfig({
  apiKey: mailchimpApi,
  server: 'us8',
});

console.log("API: ", mailchimpApi)
console.log("mailchimp: ", mailchimp)

const Footer = ({additionalClass}) => {
  const [email, setEmail] = useState("");

  const handleSendEmail = async (e) => {
    const response = await mailchimp.lists.addListMember("a2ed233af3", {
      email_address: "Ebony_Brekke@gmail.com",
      status: "pending",
    });
    console.log("RESPONSE: ", response);
    console.log("EMAIL: ", email);

  }
  return (
    <>
    <div className={`flex flex-col w-full md:w-3/5 gap-10 px-5 pb-10 mx-auto md:px-12 lg:flex-row ${additionalClass}`}>
      <div className='left basis-1/2'>
        <div className='flex items-center gap-4 logo'>
          <div className='w-3 h-3 rounded-full bg-accent'></div>
          <Logo />
        </div>
        <p className='mt-2 text-sm md:text-base w-full '>
        Our Raffle platform offers a seamless and secure way to host and participate in raffles online.
        </p>
        <div className='flex gap-4 my-6 socials'>
          <LinkedIn className='w-5' />
          <Facebook className='w-5' />
          <Instagram className='w-5' />
        </div>
      </div>
      {/*
      <div className='quick-links basis-1/3'>
        <h4 className='font-medium font-clashDisplay '>Quick Links</h4>
        <div className='flex gap-24 links'>
          <div className='left'>
            <a href='/' className='block my-4 text-base text-text-color'>
              Buy
            </a>
            <a href='/' className='block my-4 text-base text-text-color'>
              Catalog
            </a>
            <a href='/' className='block my-4 text-base text-text-color'>
              Pricing
            </a>
          </div>
          <div className='right'>
            <a href='/' className='block my-4 text-base text-text-color'>
              Wallets
            </a>
            <a href='/about' className='block my-4 text-base text-text-color'>
              Company
            </a>
          </div>
        </div>
      </div> */}
        <div className='cta basis-1/2 w-full md:w-1/5'>
          <form action="https://markets.us8.list-manage.com/subscribe/post?u=560410910d0ad6fd3d2b29c4a&amp;id=a2ed233af3&amp;f_id=00c179e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_self">
              <p className='text-lg'>Stay informed</p>
              <p className='my-4 text-sm md:text-base text-text-color'>
                Stay informed and up-to-date on our latest updates and raffle offerings
              </p>
              <div className='relative w-full input-container'>
                <input
                  type='email'
                  placeholder='Enter your email address'
                  className='w-full bg-[#2f324180] border border-card-icon  text-text-color text-xs py-4 px-4 rounded-full tex'
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button
                  type="submit"
                  text='send'
                  accent
                  additionalClass='py-2 xs:block hidden md:px-5 text-xs rounded-full absolute right-2 top-1/2 -translate-y-1/2'
                  //onClickHandler={handleSendEmail}
                />
                <ArrowIcon className='absolute block -translate-y-1/2 white-arrow xs:hidden right-4 top-1/2 stroke-stone-100' />
            </div>
        </form>
      </div>



    </div>
    <div className="flex flex-row w-full md:w-3/5 gap-10 px-5 pb-10 mx-auto md:px-12 lg:flex-row">
        <hr className="border w-full border-[#828282]" />
    </div>
    <div className="flex flex-row w-full md:w-3/5 gap-10 px-5 pb-10 mx-auto md:px-12 lg:flex-row justify-between">
        <div>
            <p className='my-4 text-xs md:text-base text-text-color'>
            Raffle Market ©. All rights reserved.
            </p>
        </div>
        <div className="flex flex-row">
            <a href='/' className='block mr-4 my-4 text-xs md:text-base text-text-color'>
              Term of Service
            </a>
            <a href='/' className='block my-4 text-xs md:text-base text-text-color'>
              Privacy Policy
            </a>
            </div>
      
    </div>
    </>
  )
}

export default Footer
