import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import parseAddress from "../../utils/parseAddress";
import Button from "../Button";
import Logo from "../Logo";
import { ReactComponent as Hamburger } from "../../assets/Hamburger.svg";
import { ReactComponent as CloseNavIcon } from "../../assets/close.svg";
import { useConnectModal, useAccount } from "@web3modal/react";
import { useGoogleLogin, hasGrantedAllScopesGoogle } from "@react-oauth/google";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import { SET_USER, GET_USER } from "../../utils/query";
//const people = require('@googleapis/people')
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'

const CLIENT2 = new ApolloClient({
  cache: new InMemoryCache(),
  uri: process.env.REACT_APP_BACKEND,
})


const navLinks = [
  { text: "About us", link: "/about" },
  { text: "Create Raffle", link: "/create" },
  { text: "Raffles", link: "/" },
  //{ text: "Profile", link: "/profile" },
  { text: "Promo Raffle", link: "/promo-raffle" },
];

const Header = ({ account }) => {
  const { isOpen, open, close } = useConnectModal();
  const [createUser, { data, loading, error }] = useMutation(SET_USER,
    { client: CLIENT2 }
  );
  const [profile, setProfile] = useState(false)
  


  const [isNavOpen, setIsNavOpen] = useState(false);
  const navigate = useNavigate();

  const connectWallet = () => {
    open();
  };



  const BACKEND = process.env.REACT_APP_BACKEND
  const scopes = ["https://www.googleapis.com/auth/contacts.readonly"]

  const handleLogin = useGoogleLogin({
    flow: 'implicit',
    scope: scopes,
      onSuccess: async (tokenResponse) => {    
        const accessToken = String(tokenResponse.access_token);
        const date1 = createUser({ variables: {
            wallet:account, googleToken: accessToken
          },
        })  
        setProfile(true)
    
  },
  onError: errorResponse => console.log(errorResponse),
  });  

  function goLink(link) {
    navigate(link);
    setIsNavOpen(false)
  }

  return (
    <div className="z-50 flex items-center justify-between w-full px-6 py-4 font-normal h-18 md:px-20 md:w-3/5 mx-auto">
      <Link to="/">
        <Logo />
      </Link>
      {isNavOpen ? (
        <CloseNavIcon
          className="cursor-pointer md:hidden w-7 h-7"
          onClick={() => setIsNavOpen(!isNavOpen)}
        />
      ) : (
        <Hamburger
          className="cursor-pointer md:hidden"
          onClick={() => setIsNavOpen(!isNavOpen)}
        />
      )}
      <div className="items-center hidden  md:flex desktop-nav">
        <nav className="flex items-center gap-8">
          {navLinks.map(({ link, text }) => (
            <Link key={text} to={link}>
              {text}
            </Link>
          ))}
          {!profile?
          <Link key={"signIn"} onClick={() => handleLogin()}>
            Sign In
          </Link>
          :
          <Link key={"signIn"} to="/profile">
            Profile
          </Link>
          }
        </nav>
        {account ? (
          <Button
            text={`${parseAddress(account)} ${
              account === process.env.REACT_APP_ADMIN_WALLET_ADDRESS ? "| " : ""
            }`}
            accent
            additionalClass="px-10 ml-20"
          />
        ) : (
          <Button
            text="Connect"
            onClickHandler={connectWallet}
            additionalClass="ml-20"
            accent
          />
        )}
      </div>
      {isNavOpen && (
        <div
          className={
            "transition-opacity duration-100 mobile-nav  z-40 absolute h-screen text-lg bg-gradient-to-b from-black to-accent top-20 bottom-0 left-0 right-0 md:hidden flex flex-col px-12 items-center text-center justify-evenly"
          }
        >
          <nav className="flex flex-col gap-8 ">
            {navLinks.map(({ link, text }) => (
              <Link to={link} onClick={() => goLink(link)}>{text}</Link>
            ))}
            {!profile?
              <Link key={"signIn"} onClick={() => handleLogin()}>
                Sign In
              </Link>
              :
              <Link key={"signIn"} to="/profile" onClick={() => goLink("/profile")}>
                Profile
              </Link>
            }
          </nav>
          {account ? (
            <Button text={parseAddress(account)} accent />
          ) : (
            <Button text="Connect" onClickHandler={connectWallet} accent />
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
