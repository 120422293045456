import React from 'react'
import ReviewCard from '../ReviewCard'

const Reviews = () => {
  return (
    <div className='md:w-4/6 w-full sm:w-5/6 mx-auto mt-24 md:px-12 px-5'>
        <h1 className='text-3xl md:text-5xl font-clashDisplay font-semibold text-center'>WHY PEOPLE LOVE RAFFLE MARKET</h1>
        <p className="subtitle text-text-color text-sm md:text-xl md:w-2/5 w-full text-center mx-auto mt-4">Raffle Market is an ideal choice for individuals seeking to generate funds for worthy causes, including yours.</p>
        <div className="reviews mt-12 flex flex-col sm:flex-wrap md:flex-nowrap  sm:flex-row items-center gap-6">
            <ReviewCard title="Enhanced Security" desc="A safe and certified random ticket selection process guarantees an equitable drawing."/>
            <ReviewCard title="Transact seamlessly" additionalClass="relative md:top-20" desc="Experience smooth transactions and hassle-free interactions with our seamless platform, designed for your convenience and satisfaction."/>
            <ReviewCard title="Blockchain Transparency" desc="Experience smooth transactions and hassle-free interactions with our seamless platform, designed for your convenience and satisfaction."/>
        </div>
    </div>
  )
}

export default Reviews