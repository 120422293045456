import React, { useRef, useState } from "react";
import RaffleCardLink from "../RaffleCardLink/RaffleCardLink";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "../Button";
import { Link, useNavigate } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import "./ProductCarousel.css";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";

const ProductCarousel = ({ title, raffles }) => {
  const navigate = useNavigate();
  return (
    <>
      <h1 className="text-3xl md:text-5xl text-center font-clashDisplay font-medium mb-12">
        {title}
      </h1>
      <Swiper
        loop={true}
        loopFillGroupWithBlank={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        autoplay={{ delay: 300000 }}
        modules={[Pagination, Navigation, Autoplay]}
        className="mySwiper"
        slidesPerView={1}
        spaceBetween={10}
        slidesPerGroup={1}
        // Responsive breakpoints
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 1,
          },
          // when window width is >= 480px
          520: {
            slidesPerView: 2,
            spaceBetween: 1,
          },
          // when window width is >= 640px
          770: {
            slidesPerView: 3,
            spaceBetween: 1,
          },
          1040: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1300: {
            slidesPerView: 6,
            spaceBetween: 10,
          },
        }}
      >
        <SwiperSlide>
          <RaffleCardLink raffle={raffles[0]} additionalClass="min-w-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <RaffleCardLink raffle={raffles[1]} additionalClass="min-w-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <RaffleCardLink raffle={raffles[2]} additionalClass="min-w-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <RaffleCardLink raffle={raffles[3]} additionalClass="min-w-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <RaffleCardLink raffle={raffles[4]} additionalClass="min-w-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <RaffleCardLink raffle={raffles[5]} additionalClass="min-w-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <RaffleCardLink raffle={raffles[6]} additionalClass="min-w-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <RaffleCardLink raffle={raffles[7]} additionalClass="min-w-[350px]" />
        </SwiperSlide>
        <SwiperSlide>
          <RaffleCardLink raffle={raffles[8]} additionalClass="min-w-[350px]" />
        </SwiperSlide>
      </Swiper>
      <div className="flex justify-center">
          <Button
              text="See more"
              additionalClass={
                "text-accent border border-accent bg-black w-full md:w-auto mt-10 mx-5"
              }
              onClickHandler={()=>{navigate("/")}}
            />
      </div>
    </>
  );
}

export default ProductCarousel;
