import React from "react";
import RaffleCard from "../RaffleCard/RaffleCard";

const RafflesContainer = ({ raffles, account, marketplaceContract, id }) => {
  raffles = raffles.slice(0);
  console.log("Raffels: ", raffles);
  return (
    <div className="flex flex-wrap gap-3 mt-6 justify-center">
      {raffles?.length
        ? raffles.map((raffle) => (
            <RaffleCard
              key={raffle.id}
              marketplaceContract={marketplaceContract}
              account={account}
              raffle={raffle}
              id={id}
            />
          ))
        : null}
    </div>
  );
};

export default RafflesContainer;
